.docs {
    &__links {
        display: flex;
        justify-content: center;
        gap: 40px;

        @include phone {
            flex-direction: column;
        }

        &__item {
            font-size: 26px;
            text-align: center;
            padding: 20px 34px;
            border-radius: 20px;
            
            iconify-icon {
                font-size: 54px;
                color: $primary;
            }

            &:hover {
                background-color: $light-gray;
            }
        }
    }
}