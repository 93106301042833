.header {
    height: 80px;
    position: fixed;
    top: 10px;
    left: 20px;
    right: 20px;
    z-index: 10;

    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    background-color: rgba(255,255,255,0.9);
    backdrop-filter: blur(10px);
    border-radius: 40px;

    @include phone {
        height: auto;
        padding: 16px 0;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @include phone {
            flex-direction: column;
        }
    }

    &__logo {
        color: $primary;
        font-size: 32px;
        font-weight: 800;
    }

    &__menu {
        display: flex;
        gap: 20px;
        list-style: none;
        padding: 0;

        @include phone {
            display: none;
        }

        &__item {
            height: 100%;
            display: flex;
            align-items: center;
            a {
                padding: 14px 28px;
                border-radius: 40px;
                cursor: pointer;
                transition: all 0.3s ease;
    
                &:hover {
                    background-color: $light-gray;
                }
            }
        }
    }

    
}