.banner {
    height: 100vh;
    background: rgb(41,129,153);
    background: linear-gradient(33deg, rgba(41,129,153,1) 0%, rgba(25,88,166,1) 100%);
    color: #fff;
    position: relative;

    &__inner {
        height: 100%;
        padding-top: 80px;
        display: flex;
        align-items: center;

        @include phone {
            flex-direction: column-reverse;
            padding: 0 10px;
            justify-content: center;
        }
    }

    &__text {

        @include phone {
            text-align: center;
        }
        &__heading {
            font-size: 64px;
            @include phone {
                font-size: 36px;
            }
        }

        &__p {
            font-size: 24px;
            @include phone {
                font-size: 18px;
            }
        }
    }

    &__image {
        width: 70%;

        img {
            width: 100%;
        }
    }

    &__arrow {
        position: absolute;
        bottom: 20px;
        left: 50%;
        font-size: 40px;
        transform: rotate(180deg) translateX(50%);
        animation: flyArrow 1.5s ease;
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }
}

@keyframes flyArrow {
    from {
        transform: rotate(180deg) translateX(50%);
    }
    to {
        transform: rotate(180deg) translateX(50%) translateY(-20px) scale(1.1);
    }
}