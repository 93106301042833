.contact {
    &__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        margin: 0 auto;
    }

    h3 {
        text-align: center;
        color: $primary;
        margin-bottom: 60px;
        font-size: 24px;
    }

    &__or {
        font-size: 26px;
        text-align: center;
        margin: 80px 0;
    }

    &__phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        &__number {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 32px;
            gap: 20px;

            &:hover {
                text-decoration: underline;
            }

            iconify-icon {
                color: $primary;
                transform: translateY(2px);
                font-size: 40px;
            }
        }
    }

    .form {
        margin: 0 auto;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        
        &__input, &__textarea {
            margin-bottom: 20px;
            padding: 20px 26px;
            outline: none;
            font-size: 18px;
            border-radius: 20px;
            border: 2px solid $light-gray;
            transition: border 0.2s ease;
            font-family: inherit;
        }

        .types {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin-bottom: 40px;
            padding-left: 20px;

            @include phone {
                grid-template-columns: 1fr;
            }

            .choice {
                display: flex;
                gap: 20px;
            }
        }

        input[type="radio"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 28px;
            height:  28px;
            border: 3px solid $light-gray;
            border-radius: 50%;
            transform: translateY(-3px);
            display: grid;
            place-content: center;
            cursor: pointer;
            
            &::before {
                content: "";
                width: 17px;
                height: 17px;
                border-radius: 50%;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em $primary;
              }

            &:checked {
                &::before {
                    transform: scale(1);
                }
            }
        }          

        &__textarea {
            resize: none;
            height: 200px;
        }

        &__input:focus, &__textarea:focus {
            border: 2px solid $primary;
        }


        .button {
            margin-top: 30px;
            font-size: 20px;
        }

        h3 {
            text-align: center;
        }
    }
}