.services {
    &__inner {
        
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @include phone {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        background: rgb(41,129,153);
        background: linear-gradient(33deg, rgba(41,129,153,1) 0%, rgba(25,88,166,1) 100%);
        color: #fff;
        box-shadow: 0 0 16px rgba(0,0,0,0.2);
        border-radius: 20px;
        padding: 20px 40px;
        cursor: pointer;

        transition: all 0.2s ease;

        @include phone {
            width: 100%;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include phone {
                flex-direction: column;
                justify-content: center;
            }
        }

        &__title {
            font-size: 32px;

            @include phone {
                font-size: 26px;
                text-align: center;
            }
        }

        &__icon {
            font-size: 60px;
        }

        &:hover {
            transform: scale(1.05);
            box-shadow: 0 10px 16px rgba(0,0,0,0.5);
        }
    }
}