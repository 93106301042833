@import "vars";
@import "mixins";
@import "./components/header.scss";
@import "./components/banner.scss";
@import "./components/docs.scss";
@import "./components/services.scss";
@import "./components/reviews.scss";
@import "./components/contact.scss";
@import "./components/footer.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto' ,Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

section {
  padding: 100px 0;

  .section__heading {
    font-size: 32px;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 100px;
    border-bottom: 8px solid $primary;
    padding-bottom: 10px;
  }
}

.container {
  height: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;

  @include phone {
  width: 100%;
  padding: 0 10px;
  }
}

main {
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

.button {
  @include primary();
  padding: 14px 28px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border: none;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);

  &:hover {
    background-color: darken($color: $primary, $amount: 10%);
  }

  &[disabled] {
    background-color: #737373;
    cursor: not-allowed;
  }
}
