.reviews {
    &__list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;

        @include phone {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 20px rgba(0,0,0,0.2);
        padding: 20px 30px;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0 16px;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 16px;
            border-bottom: 2px solid $primary;

            @include phone {
                flex-direction: column-reverse;
            }
        }

        &__rating {
            color: $primary;
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 20px 0;
            color: $mid-gray;
        }
    }
}