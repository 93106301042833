.footer {
    width: 100%;
    background: rgb(41,129,153);
        background: linear-gradient(33deg, rgba(41,129,153,1) 0%, rgba(25,88,166,1) 100%);
    color: white;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    text-align: center;
}